import React from "react";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";

const VKPlayer = ({ src }) => {
	if (!src) {
		return null;
	}

	return (
		<iframe
			src={src}
			width="100%"
			height={isMobile ? 200 : 650}
			allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;"
			frameBorder="0"
			allowFullScreen
		/>
	);
};

export default VKPlayer;

VKPlayer.propTypes = {
	src: PropTypes.string,
};
